/**
 * Profile component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

const Nav = () => {
  const [path, setPath] = React.useState(null);
  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setPath(window.location.pathname?.replaceAll("/", ""));
    }
  }, [])

  return (
    <nav id="nav">
      {["experience"].includes(path) ? (
        <Link to="/">
          <StaticImage
            src="../images/logo.png"
            width={30}
            height={30}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="A Gatsby astronaut"
            style={{ borderRadius: `.45rem` }}
          />
        </Link>
      ) : (
        <Link to="/experience">Experience</Link>
      )}
      <OutboundLink target="_blank" rel="noreferrer" href="https://chike.build">
        Blog
      </OutboundLink>
      <a
        target="_blank"
        rel="noreferrer"
        href="https://twitter.com/chikeozulumba"
      >
        Twitter
      </a>
      <OutboundLink
        target="_blank"
        rel="noreferrer"
        href="https://linkedin.com/in/chike-ozulumba-360626b1/"
      >
        Linkedin
      </OutboundLink>
      <OutboundLink
        target="_blank"
        rel="noreferrer"
        href="https://github.com/chikeozulumba"
      >
        Github
      </OutboundLink>
    </nav>
  )
}

export default Nav
